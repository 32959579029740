import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormCheckbox,
  BFormCheckboxGroup
} from 'bootstrap-vue';
import {
  OrderNamespace,
  OrderGetter,
  OrderAction
} from '@/store/order/order.module-types';
import {
  OrderViewModel,
  OrderStatus,
  CutType,
  OrderDeliveryType,
  OrderDetailType,
  CustomerLevel,
  OrderFormularType
} from '@/api/api';
import {
  convertCurrencyToNumber,
  enumToDropdownOptions
} from '@/utility/utils';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { dispatchOrderAction } from '@/store/order/order.dispatch';
import { quillEditor } from 'vue-quill-editor';
import { formatCurrency, formatDate, formatThousand } from '@/utility/utils';
import { VueGoodTable } from 'vue-good-table';
import { COMPANY_INVOICE_INFO } from '@/utility/constants';
import PrintOrderDetailTable from '../PrintOrderDetailTable.vue';
import PrintOrderProducingTable from '../PrintOrderProducingTable.vue';
import ApiClientFactory from '@/api/apiClientFactory';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueGoodTable,
    PrintOrderDetailTable,
    PrintOrderProducingTable
  }
})
export default class OrderDetailPrint extends Vue {
  @OrderNamespace.Getter(OrderGetter.order)
  order!: OrderViewModel;

  companyInvoiceInfo: any;

  orderId = '';
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  OrderDetailType = OrderDetailType;
  orderStatusOptions: DropdownOption[] = enumToDropdownOptions(OrderStatus);
  cutTypeOptions: DropdownOption[] = enumToDropdownOptions(CutType);
  OrderDeliveryType = OrderDeliveryType;
  formatCurrency = formatCurrency;
  formatThousand = formatThousand;
  formatDate = formatDate;
  PrintType = PrintType;
  printType = PrintType[0];
  CustomerLevel = CustomerLevel;
  OrderFormularType = OrderFormularType;

  orderDeliveryTypeOptions: DropdownOption[] = enumToDropdownOptions(
    OrderDeliveryType
  );

  get billEmployeePhoneNumber() {
    return (
      this.order.employeePhoneNumber ?? this.companyInvoiceInfo.companyPhone
    );
  }

  displayOrderAdress(value) {
    if (value.deliveryType !== OrderDeliveryType.ToMyself) {
      const deliveryType = this.orderDeliveryTypeOptions.find(
        (s) => s.value === value.deliveryType
      );
      return deliveryType
        ? `${this.$t(deliveryType.text)}: ${this.truncateAddress(
            value.customerAddress
          )}`
        : '';
    }
    return '';
  }

  displayCutType(value) {
    return this.cutTypeOptions.find((x) => x.value === value)
      ? this.$t(
          this.cutTypeOptions.find((x) => x.value === value)?.text as string
        )
      : '';
  }

  mounted() {
    this.orderId = this.$route.params.id;
    const printTypeVal = this.$route.query.type.toString() ?? '0';
    this.printType = PrintType[printTypeVal];
    dispatchOrderAction(OrderAction.loadOrder, +this.orderId);
    this.companyInvoiceInfo = JSON.parse(
      localStorage.getItem(COMPANY_INVOICE_INFO) ?? ''
    );
  }

  @Watch('order')
  orderLoaded() {
    this.$nextTick().then(() => {
      if (this.order.orderDetails && this.order.orderDetails?.length > 0) {
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    });
  }

  onPrint() {
    const html = document.getElementById('orderDetail');
    const newWindow = window.open(
      '/print-order-detail',
      '_blank',
      `width=1000,height=${window.innerHeight}`
    );
    if (newWindow === null) return;

    newWindow.onload = function () {
      const printBody = newWindow.document.getElementById('printBody');
      const child = html?.children[0];
      printBody?.prepend(child!.cloneNode(true));
    };
  }

  getCompanyAddress() {
    let address = this.companyInvoiceInfo.companyAddress;
    if (this.order?.deliveryType === OrderDeliveryType.ByMyself) {
      address =
        this.order.customerAddress ?? this.companyInvoiceInfo.companyAddress;
    }

    return this.truncateAddress(address);
  }

  truncateAddress(value) {
    return value.replace(/Địa chỉ 1|Địa chỉ 2|Văn phòng|: /gi, '');
  }

  getCurrentDate() {
    const m = new Date();
    return (
      ('0' + m.getHours()).slice(-2) +
      ':' +
      ('0' + m.getMinutes()).slice(-2) +
      ' - ' +
      ('0' + m.getDate()).slice(-2) +
      '/' +
      ('0' + (m.getMonth() + 1)).slice(-2) +
      '/' +
      m.getFullYear()
    );
  }
}

export enum PrintType {
  OrderDetail = 'OrderDetail',
  OrderProducing = 'OrderProducing'
}
